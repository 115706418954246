//import http from "./api/http-common";
import httpAuth from "./api/http-auth";

import Auth from "./api/auth";
import Init from "./api/init";

import Upload from "./api/upload";
import Google from "./api/google";

import User from './api/user';
import Users from './api/users';
import Units from './api/units';
import UnitsGroups from './api/units-groups';
import UnitsKeys from './api/units-keys';
import UnitsKeysOrders from './api/units-keys-orders';
import Geozones from './api/geozones';
import GeozonesGroups from './api/geozones-groups';
import Geopoints from './api/geopoints';
import GeopointsGroups from './api/geopoints-groups';

import ReportsTemplates from './api/reports-templates';
import Reports from './api/reports';
import Notifications from './api/notifications';
import NotificationsEvents from './api/notifications-events';
import UnitsMsgs from './api/units-msgs';

import Trips from './api/trips';
import Parkings from './api/parkings';
import Idlings from './api/idlings';
import Events from './api/events';
import Screenshots from './api/screenshots';
import VideoEvents from './api/video-events';
import VideoRequests from './api/video-requests';
import VideoRecords from './api/video-records';
import VideoStatuses from './api/video-statuses';
import Stops from './api/stops';
import Tracks from './api/tracks';

import Products from './api/products'
import UnitsHwTypes from './api/units-hw-types'

import TiveShipments from './api/tive-shipments'
import TiveShipmentTemplates from './api/tive-shipment-templates'
import TiveShipmentAlerts from './api/tive-shipment-alerts'
import TiveShipmentTracks from './api/tive-shipment-tracks'
import TiveDevices from './api/tive-devices'
import TiveCarriers from './api/tive-carriers'
import TiveAlertPresets from './api/tive-alert-presets'
import TiveLocations from './api/tive-locations'
import TiveUsers from './api/tive-users'
import ShipmentsTypes from './api/shipments-types'

import AccessRights from './api/access-rights'
import Locator from './api/locator'
import Weather from './api/weather'
import Statistics from "./api/statistics";
import Analytics from "./api/analytics";
import ObjectsAudit from "@/api/objects-audit";
import Commands from "@/api/commands";
import Customize from './api/customize';

import Exports from './api/exports';

import Locations from './api/locations';

import Entri from "./api/entri";
import Site from './api/site';

import config from "@/config";
httpAuth.defaults.baseURL = config.api.baseURL + '/' + (config?.api?.ver || '')
//console.log(httpAuth.defaults)

// Initialize all or API factories
const factories = {
    auth: Auth(),
    locations: Locations(),
    accessrights: AccessRights(),
    init: Init(),
    upload: Upload(),
    google: Google(),

    user: User(httpAuth),

    products: Products(httpAuth),
    unitshwtypes: UnitsHwTypes(httpAuth),

    users: Users(httpAuth),
    units: Units(httpAuth),
    unitsgroups: UnitsGroups(httpAuth),
    geozones: Geozones(httpAuth),
    geozonesgroups: GeozonesGroups(httpAuth),
    geopoints: Geopoints(httpAuth),
    geopointsgroups: GeopointsGroups(httpAuth),

    reportstemplates: ReportsTemplates(httpAuth),
    reports: Reports(httpAuth),
    notifications: Notifications(httpAuth),
    commands: Commands(httpAuth),

    trips: Trips(httpAuth),
    parkings: Parkings(httpAuth),
    idlings: Idlings(httpAuth),
    events: Events(httpAuth),
    screenshots: Screenshots(httpAuth),
    videoEvents: VideoEvents(httpAuth),
    videoRequests: VideoRequests(httpAuth),
    videoRecords: VideoRecords(httpAuth),
    stops: Stops(httpAuth),
    tracks: Tracks(httpAuth),
    notificationsevents: NotificationsEvents(httpAuth),

    unitsmsgs: UnitsMsgs(httpAuth),
    videoStatuses: VideoStatuses(httpAuth),

    unitskeysorders: UnitsKeysOrders(httpAuth),
    unitskeys: UnitsKeys(httpAuth),

    objectsaudit: ObjectsAudit(httpAuth),
    customize: Customize(httpAuth),

    tiveshipments: TiveShipments(httpAuth),
    tiveshipmenttemplates: TiveShipmentTemplates(httpAuth),
    tiveshipmentalerts: TiveShipmentAlerts(httpAuth),
    tiveshipmenttracks: TiveShipmentTracks(httpAuth),
    tivedevices: TiveDevices(httpAuth),
    tivecarriers: TiveCarriers(httpAuth),
    tivealertpresets: TiveAlertPresets(httpAuth),
    tivelocations: TiveLocations(httpAuth),
    tiveusers: TiveUsers(httpAuth),
    shipmentstypes: ShipmentsTypes(httpAuth),

    locator: Locator(httpAuth),
    weather: Weather(httpAuth),
    statistics: Statistics(httpAuth),
    analytics: Analytics(httpAuth),
    exports: Exports(httpAuth),
    entri: Entri(httpAuth),

    site: Site(httpAuth),
};

// Vue.$api = factories;
// store.$api = factories

export default factories;
